var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h2',{staticClass:"mb-4"},[_vm._v("Эксперты")]),_c('experts-filters',{staticClass:"mb-5",attrs:{"table-params":_vm.tableParams},on:{"change-field":_vm.changeField}}),_c('data-table',{ref:"dataTable",attrs:{"url":"experts","headers":_vm.headers,"table-params":_vm.tableParams},scopedSlots:_vm._u([{key:"item.photo",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('v-img',{staticClass:"rounded-circle",attrs:{"src":item.photo,"width":"44","height":"44"}})],1)]}},{key:"item.latestRatingDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.latestRatingDate))+" ")]}},{key:"item.sector",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("sectors")(item.sector,{ type: "short" }))+" ")]}},{key:"item.followersCount",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-inline-flex"},[_c('v-text-field',{key:item.id,attrs:{"value":item.followersCount,"rules":[function (value) { return _vm.validateFollowersCount(value) || 'Недопустимое значение'; }],"dense":"","hide-details":""},on:{"change":function($event){return _vm.updateFollowersCount(item, $event)}}})],1)]}},{key:"item.isActive",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("yesNo")(item.isActive))+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('v-btn',{attrs:{"icon":"","color":"accent"},on:{"click":function($event){return _vm.editExpert(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pencil")])],1)],1)]}}],null,true)}),_c('v-divider',{staticClass:"my-6"}),_c('div',{staticClass:"text-h6 mb-3"},[_vm._v("Новый аналитик")]),_c('experts-create-expert-form')],1)}
var staticRenderFns = []

export { render, staticRenderFns }